<template>
  <div>
    <vuestic-widget :loading="loading">
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Widget Landing Page</span>
          <div class="d-flex align-items-start justify-content-between">
            <a :href="'//' + landingUrl" target="_blank" class="btn btn-sm btn-primary mr-1">Go To Landing Page</a>
            <div class="text-right">
              <button class="btn btn-sm btn-primary ml-1" @click="editPage('terms-of-services')">
                Terms of Services
              </button>
              <button class="btn btn-sm btn-primary ml-1" @click="editPage('privacy-policy')">
                Privacy Policy
              </button>
              <button class="btn btn-sm btn-primary ml-1" @click="editCustomScript" :disabled="isLoadingCustomScript">
                <atom-spinner v-if="isLoadingCustomScript" slot="loading" :animation-duration="1500" :size="14" color="#3578c6"/> Add Install Script
              </button>
            </div>
          </div>
        </div>
      </template>
      <div>
        <h4>Prospects</h4>
        <datatable v-bind="datatable" class="le-datatable"></datatable>
      </div>
    </vuestic-widget>

    <vuestic-modal
      class="edit-page-modal"
      :isOpen="isOpenEditPage"
      @ok="handleSavePage"
      @cancel="closeModalEditPage"
      okText="Save"
      :closeOnOk="false"
      cancelText="Cancel"
    >
      <span slot="title">Edit page</span>
      <div v-if="selectedPage">
        <text-input label="Key" v-model="selectedPage.key" name="key" disabled></text-input>
        <text-input label="Title" v-model="selectedPage.title" name="title"></text-input>
        <textarea ref="editor"></textarea>
      </div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalCustomScript"
      @ok="handleSaveCustomScript"
      @cancel="closeModalCustomScript"
      okText="Save"
      cancelText="Cancel"
    >
      <span slot="title">Add Install Script</span>
      <div class="form-group">
        <textarea v-model="sitedata.custom_script" rows="6" class="custom-script-textarea form-control" placeholder="Script..."></textarea>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>

import components from "../common/tables/comps";

// froala-editor
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

export default {
  components: {  },

  data () {
    return {
      datatable: {
        HeaderSettings: false,
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          const cols = [
            { title: '#', field: 'id', label: '#', sortable: false, tdStyle: {fontStyle: "normal"}, tdClass: "center" },
            { title: 'Full name', sortable: false, field: 'full_name', tdStyle: {fontStyle: "normal"}, tdClass: "center"},
            { title: 'Business name', field: 'business_name', sortable: false, tdStyle: {fontStyle: "normal"}, tdClass: "center"},
            { title: 'Email', field: 'email', sortable: false, tdStyle: {fontStyle: "normal"}, tdClass: "center", tdComp: "Email"},
            { title: 'Phone', field: 'phone', sortable: false, tdStyle: {fontStyle: "normal"}, tdClass: "center"},
            { title: 'Created at', field: 'created_at', tdStyle: {fontStyle: "normal"}, tdClass: "center", tdComp: "TdNestedDateValue"},
          ]

          return cols
        })(),
        data: [],
        selection: [],
        query: {},
        total: 0,
      },
      loading: false,
      isOpenModalCustomScript: false,
      isLoadingCustomScript: false,
      isOpenEditPage: false,
      selectedPage: null,
      editor: null,
      editorInitialized: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_API_KEY,
        heightMin: 100,
        heightMax: 500,
        quickInsertEnabled: false,
        toolbarVisibleWithoutSelection: false,
        useClasses: false,
        enter: FroalaEditor.ENTER_BR,
        fontFamilySelection: true,
        fontSizeSelection: true,
        linkAlwaysBlank: true,
        linkStyles: {
          'fr-green': 'Green',
          'fr-strong': 'Thick',
          'underlineClass': 'Underline',
        },
        toolbarButtons: {
          'moreText': {
            'buttons': [
              'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
              'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
              'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
            ],
            'buttonsVisible': 10,
          },
        },
        emoticonsUseImage: false,
        requestHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        imageUpload: true,
        imageUploadMethod: 'POST',
        imageUploadParam: 'image',
        imageUploadParams: {
          link: true,
        },
        imageUploadRemoteUrls: true,
        imageUploadURL: process.env.VUE_APP_BASE_API + 'api/v1/image',
        ...this.options,
        events: {
          initialized: () => {
            this.editor.html.set(this.selectedPage.content)
            this.initEvents()
          },
          blur: () => { 
            this.editor.selection.save();
          },
          destroy: () => { }
        },
      }
    }
  },

  mounted () {
  },

  methods: {
    initEditor() {
      if (!this.editorInitialized) {
        this.editor = new FroalaEditor(this.$refs.editor, this.editorConfig)
        this.editorInitialized = true
      }
    },
    initEvents() {
      let _this = this
      _this.editor.events.on('contentChanged', function (e) {
        if (_this.selectedPage.content !== _this.editor.html.get()) {
          _this.selectedPage.content = _this.editor.html.get()
        }
      }, false);

    },
    handleQueryChange () {
      this.loading = true
      this.$store.dispatch('agency/getOptinUsers', this.datatable.query)
        .then(res => {
          this.loading = false
          this.datatable.data = res.data.data
          this.datatable.total = res.data.meta.total
        })
        .catch(() => {
          this.loading = false
        })
    },

    editCustomScript () {
      this.isOpenModalCustomScript = true
    },

    closeModalCustomScript () {
      this.isOpenModalCustomScript = false
    },

    handleSaveCustomScript () {
      this.isOpenModalCustomScript = false
      this.isLoadingCustomScript = true

      this.$store.dispatch('agency/updateCustomScript', { custom_script: this.sitedata.custom_script })
        .then(() => {
          this.isLoadingCustomScript = false
        })
        .catch(() => {
          this.isLoadingCustomScript = false
        })
    },

    editPage (key) {
      this.$store.dispatch('page/getPage', key)
        .then((res) => {
          this.isOpenEditPage = true
          this.selectedPage = res.data.data
          if (this.editor) {
            this.editor.html.set(this.selectedPage.content)
          }
          this.$nextTick(() => this.initEditor());
        })
        .catch(() => {
        })
    },

    handleSavePage () {
      this.$store.dispatch('page/updatePage', this.selectedPage)
        .then((res) => {
          this.closeModalEditPage()
        })
        .catch(() => {
        })
    },

    closeModalEditPage () {
      this.isOpenEditPage = false
    },
  },

  computed: {
    landingUrl () {
      if (this.$store.state.auth.user)
      {
        const whitelabel = {...this.$store.state.auth.user.agency.whitelabel}
        const subdomain_url = this.$store.state.auth.user.agency.subdomain_url + '.' + this.$store.state.app.sitedata.whitelabel_domain;
        return whitelabel.domain ? whitelabel.domain : subdomain_url;
      }
      return '';
    },

    sitedata () {
      return this.$store.state.app.sitedata
    }
  },

  watch: {
    'datatable.query': {
      handler () {
        this.handleQueryChange()
      },
      deep: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.atom-spinner {
  display: inline-block;
}
.custom-script-textarea {
  background-color: #e8e8e8;
  border: 2px solid #eee;
  font-size: 14px;
  line-height: 22px;
  word-break: break-all;

  &:active,
  &:focus,
  &:hover {
    border: 2px solid #ccc;
    box-shadow: none !important;
  };
}
</style>

<style lang="scss">
.edit-page-modal {
  .modal-dialog {
    max-width: 100%;
    width: 1080px;
    .ql-editor {
      color: #414141;
    }
  }
}
</style>
